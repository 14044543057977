import React from "react";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong } from "@fortawesome/free-solid-svg-icons";
import QuiSommesNous from "./QuiSommesNous";
import ContactForm from "./Form/ContactForm";
import ScrollToTopButton from "./Bouton/ScrollToTopButton";
import "../style/Home.css";

function HomeTest() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Bulle, services de ménage pour professionnels et particuliers dans le Var. Services de nettoyage de bureau, de copropriété, de fin de chantier et industriel."
        />
        <title>
          Bulle - Nettoyage dans le Golfe de Saint-Tropez et la Dracénie
        </title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="xxs">
        <section style={{ overflow: "hidden" }}>
          <Row className="bg-img">
            <Col className="d-flex align-items-end justify-content-center mb-5">
              <h1>
                Ne restez pas dans votre bulle,{" "}
                <HashLink to={"/#contact"} className="link-style">
                  contactez-nous <FontAwesomeIcon icon={faDownLong} bounce />
                </HashLink>
              </h1>
            </Col>
          </Row>
        </section>

        <section id="QuiSommesNous" className="border-basse">
          <QuiSommesNous />
        </section>

        <section id="contact">
          <ContactForm />
        </section>

        <ScrollToTopButton />
      </Container>
    </div>
  );
}

export default HomeTest;
