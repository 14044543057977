import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgVoiture from "../../assets/img_voiture_int.png";
import "../../style/Services.css";

function Voiture() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Bulle propose des services de nettoyage de voiture dans le Var. Des solutions professionnelles pour maintenir la propreté et l'éclat de votre véhicule."
        />
        <title>Bulle - Nettoyage de Voiture dans le Var</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          {/* <Col xs={12} className='d-flex justify-content-center align-items-center'>
                        <h1 className="titre">Nettoyage de voiture</h1>
                    </Col> */}
          <Col xs={12} lg={6} className="texte-justify">
            <h1 className="titre mb-4">Nettoyage de voiture</h1>
            <p>
              Chez Bulle, nous sommes fiers de vous offrir un service de
              nettoyage automobile exceptionnel qui prend soin de votre véhicule
              tout en préservant l'environnement. En tant que pionniers du
              nettoyage écologique de voitures, nous nous engageons à offrir des
              résultats impeccables sans compromettre la santé de notre planète.
            </p>
            <p>Nos services de nettoyage écologique de voitures incluent :</p>
            <ul className="liste">
              <li className="mb-2">
                <strong>Lavage sans eau : </strong>
                Nous utilisons des techniques de lavage innovantes qui éliminent
                le besoin d'eau excessive, contribuant ainsi à la conservation
                des ressources précieuses.
              </li>
              <li className="mb-2">
                <strong>Produits respectueux de l'environnement : </strong>
                Nos produits de nettoyage sont spécialement formulés pour être
                biodégradables et non toxiques, assurant un nettoyage efficace
                sans laisser de traces néfastes.
              </li>
              <li className="mb-2">
                <strong>Intérieur écologique : </strong>
                Nous prenons soin de l'intérieur de votre véhicule en utilisant
                des produits qui préservent la qualité de l'air et la santé des
                occupants, tout en offrant un intérieur frais et propre.
              </li>
              <li className="mb-2">
                <strong>Protection de la peinture : </strong>
                Notre service de nettoyage inclut une protection de la peinture
                respectueuse de l'environnement pour préserver la brillance de
                votre voiture tout en minimisant l'impact sur la nature.
              </li>
              <li className="mb-2">
                <strong>Gestion responsable des déchets : </strong>
                Nous nous engageons à réduire les déchets au minimum en
                recyclant autant que possible et en utilisant des méthodes de
                gestion des déchets respectueuses de l'environnement.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center img-padding"
          >
            <Image
              src={ImgVoiture}
              alt="Voiture"
              className="img-fluid mx-auto"
              roundedCircle
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-4">
            <h2 className="titre">
              Pourquoi choisir Bulle pour le nettoyage écologique de votre
              voiture ?
            </h2>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <ul className="liste-choix">
              <li>
                <strong>Engagement environnemental : </strong>
                Nos équipes qualifiées et professionnelles sont formées pour
                travailler dans des environnements résidentiels, assurant un
                service respectueux et discret.
              </li>
              <li>
                <strong>Confort et efficacité : </strong>
                Nous sommes passionnés par la préservation de l'environnement.
                En choisissant nos services, vous contribuez à la réduction de
                l'empreinte carbone associée au nettoyage automobile.
              </li>
              <li>
                <strong>Flexibilité : </strong>
                Que vous ayez besoin d'un nettoyage ponctuel ou d'un programme
                d'entretien régulier, nous sommes flexibles et adaptons nos
                services à vos besoins.
              </li>
              <li>
                <strong>Mobilité : </strong>
                Nous pouvons nous déplacer sur votre lieu de travail.
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <p>
              Optez pour le nettoyage écologique de votre voiture avec Bulle et
              découvrez comment nous pouvons allier performance, éthique et
              responsabilité environnementale. Contactez-nous dès aujourd'hui
              pour réserver votre session de nettoyage et offrez à votre
              véhicule l'attention qu'il mérite, tout en respectant notre belle
              planète.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Voiture;
