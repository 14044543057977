import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import '../../style/Bouton/ScrollToTopButton.css'

function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    // Gère le défilement et affiche ou masque le bouton en conséquence
    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setIsVisible(scrollTop > 100); // Vous pouvez ajuster ce seuil selon vos préférences
    };

    // Défilez vers le haut de la page lorsqu'on clique sur le bouton
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    // Ajoute un gestionnaire d'événements de défilement une fois que le composant est monté
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Button
            variant="dark"
            className={`scroll-to-top-button ${isVisible ? "visible" : "hidden"}`}
            onClick={scrollToTop}
        >
            <FontAwesomeIcon icon={faArrowUp} />
        </Button>
    );
}

export default ScrollToTopButton;
