import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgFinChantier from "../../assets/img_fin_chantier.png";
import "../../style/Services.css";

function FinChantier() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Bulle propose des services de nettoyage de fin de chantier dans le Var. Solutions professionnelles pour un nettoyage complet après la construction ou la rénovation."
        />
        <title>Bulle - Nettoyage de Fin de Chantier dans le Var</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          {/* <Col xs={12} className='d-flex justify-content-center align-items-center'>
                        <h1 className="titre">Nettoyage de fin de chantier</h1>
                    </Col> */}
          <Col xs={12} lg={6} className="texte-justify">
            <h1 className="titre mb-4">Nettoyage de fin de chantier</h1>
            <p>
              Votre chantier touche à sa fin et vous avez besoin de le faire
              nettoyer ? Faites appel à Bulle, son dirigeant travaillant dans le
              bâtiment depuis plus de 25 ans, nous saurons mieux que personne
              comprendre vos besoins.
            </p>
            <p>
              Chez Bulle, nous comprenons l'importance d'une finition impeccable
              après la réalisation de travaux de construction ou de rénovation.
              Notre service de nettoyage de fin de chantier est conçu pour
              garantir que votre espace retrouve son éclat d'origine, prêt à
              accueillir ses occupants. Nous sommes fiers de vous offrir des
              services de nettoyage de fin de chantier exceptionnels, adaptés à
              vos besoins spécifiques. Ce nettoyage pourra laisser à vos clients
              l’opportunité d’admirer la qualité de travail de vos employés en
              le mettant en valeur.
            </p>
            <p>
              Nous assurons un nettoyage approfondi de votre chantier comme :
            </p>
            <ul className="liste">
              <li className="mb-2">
                <strong>Élimination des débris de construction : </strong>Nos
                équipes expérimentées se chargent de retirer efficacement tous
                les débris de construction, y compris les résidus de matériaux,
                les emballages et autres déchets liés aux travaux.
              </li>
              <li className="mb-2">
                <strong>Nettoyage en profondeur : </strong>Nous procédons à un
                nettoyage en profondeur de toutes les surfaces, éliminant les
                traces de plâtres, de peintures, de colles et autres résidus de
                construction.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des vitres : </strong>Nous assurons le
                nettoyage complet des vitres, garantissant une visibilité
                maximale et une entrée de lumière naturelle dans l'espace.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des sols : </strong>Qu'il s'agisse de
                revêtements de sol en carrelage, en bois, en béton ou autre,
                nous utilisons des techniques spécialisées pour un nettoyage
                optimal et la restauration de l'aspect neuf.
              </li>
              <li className="mb-2">
                <strong>Désinfection : </strong>Nous effectuons une désinfection
                approfondie des surfaces pour assurer un environnement sûr et
                sain après les travaux.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center img-padding"
          >
            <Image
              src={ImgFinChantier}
              alt="Fin de chantier"
              className="img-fluid mx-auto"
              roundedCircle
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-4">
            <h2 className="titre">
              Pourquoi Choisir BULLE pour le Nettoyage de vos fins de chantier ?
            </h2>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <ul className="liste-choix">
              <li>
                <strong>Expertise spécialisée : </strong>Nos équipes sont
                formées pour gérer les défis spécifiques liés au nettoyage de
                fin de chantier, garantissant un résultat impeccable.
              </li>
              <li>
                <strong>Respect des délais : </strong>Nous comprenons
                l'importance de respecter les délais de fin de chantier. Notre
                équipe s'engage à livrer un travail efficace dans les délais
                convenus.
              </li>
              <li>
                <strong>Matériel et produits adaptés : </strong>Nous utilisons
                des équipements et des produits de nettoyage spécialement conçus
                pour les environnements post-construction, assurant un résultat
                optimal sans compromettre la qualité.
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={6} className="text-justify">
            <p>
              Nous nous engageons à fournir tous les produits et matériels
              nécessaires, et à vous confier la meilleure équipe que nous avons.
            </p>
            <p>
              Confiez le nettoyage de fin de chantier à Bulle et soyez assuré
              que votre espace sera prêt à être occupé sans aucun souci.
              Contactez-nous dès aujourd'hui pour discuter de vos besoins
              spécifiques et découvrez comment nous pouvons vous aider à
              finaliser votre projet avec succès.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FinChantier;
