import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import "../style/Services.css";

function Intervenants() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Découvrez nos intervenants experts en ménage et nettoyage dans le Var. Équipe qualifiée pour des services impeccables aux professionnels et particuliers."
        />
        <title>Bulle - Intervenants en Ménage et Nettoyage</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row>
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <h1 className="titre">Intervenants</h1>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <h5>Chers clients professionnels,</h5>
            <p>
              Nous tenons à mettre en lumière un aspect crucial de notre service
              qui contribue grandement à l'image et à l'efficacité de votre
              entreprise : nos intervenantes de ménage dévouées.
            </p>
            <p>
              Dans l'arrière-plan de vos bureaux bien ordonnés, de vos espaces
              commerciaux accueillants et de vos installations impeccables, se
              trouvent nos intervenantes de ménage, les gardiennes invisibles de
              la propreté et du professionnalisme qui imprègnent chaque aspect
              de votre entreprise.
            </p>
            <p>
              Leur travail discret mais essentiel va bien au-delà du simple
              nettoyage. Elles sont les architectes de la première impression
              que vos clients, partenaires et employés ont de votre entreprise.
              Leur professionnalisme, leur minutie et leur dévouement sont les
              piliers sur lesquels repose la réputation de votre entreprise.
            </p>
            <p>
              Nos intervenantes de ménage comprennent l'importance de maintenir
              un environnement de travail propre et organisé. Leur travail
              contribue à créer une atmosphère de productivité et de bien-être
              pour vos employés, ce qui se traduit par une meilleure efficacité
              et un moral plus élevé.
            </p>
            <p>
              Leur engagement envers l'excellence est sans faille. Elles
              travaillent avec discrétion et efficacité, respectant vos horaires
              chargés et vos exigences spécifiques en matière de propreté. Leur
              présence fiable et leur souci du détail vous permettent de vous
              concentrer sur votre cœur de métier en toute confiance, sachant
              que votre environnement de travail est entre de bonnes mains.
            </p>
            <p>
              Nous vous encourageons à reconnaître et à valoriser leur travail
              souvent sous-estimé. Un simple geste de reconnaissance, un mot de
              remerciement ou même une brève interaction pour exprimer votre
              appréciation peuvent avoir un impact significatif sur leur
              motivation et leur engagement envers la qualité de leur travail.
            </p>
            <p>
              En conclusion, nos intervenantes de ménage pour professionnels
              sont bien plus que des employées ; elles sont des partenaires
              indispensables dans la réussite de votre entreprise. Leur travail
              acharné et leur engagement envers l'excellence contribuent à créer
              un environnement de travail propice à la productivité et au
              succès.
            </p>
            <p>
              Nous vous remercions de reconnaître et de valoriser l'importance
              de leur contribution à votre entreprise.
            </p>
            <p>Cordialement,</p>
            <p>L'équipe Bulle</p>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <h5>Chers particuliers,</h5>
            <p>
              Nous souhaitons prendre un moment pour vous parler de ceux qui
              font briller chaque coin de votre foyer, ceux dont le travail
              discret mais essentiel contribue à votre bien-être quotidien : nos
              intervenantes de ménage.
            </p>
            <p>
              Ces professionnelles dévouées, bien plus que de simples
              nettoyeuses, sont les gardiennes de l'hygiène et du confort de
              votre maison. Leur présence discrète mais efficace est un maillon
              essentiel dans la chaîne du bien-être domestique. Leur
              méticulosité transcende les tâches ménagères ordinaires ; elles
              injectent une dose de soin et d'attention dans chaque recoin de
              votre espace de vie.
            </p>
            <p>
              Derrière chaque pièce impeccable se cache le dévouement et le
              savoir-faire de nos intervenantes. Elles ne se contentent pas de
              nettoyer, elles créent un environnement où vous vous sentez
              réellement chez vous. Leur travail méticuleux va bien au-delà de
              l'aspect esthétique ; il vise à créer un sanctuaire où vous pouvez
              vous détendre et vous ressourcer.
            </p>
            <p>
              Leur professionnalisme est irréprochable. Elles travaillent avec
              discrétion et respectent votre intimité, comprenant que votre
              maison est bien plus qu'un simple espace physique, c'est le
              sanctuaire de votre vie. Leur présence apporte une tranquillité
              d'esprit, sachant que votre maison est entre de bonnes mains.
            </p>
            <p>
              Nous vous encourageons à reconnaître leur travail et leur
              dévouement. Un simple sourire, un mot de remerciement, ou même une
              brève conversation pour montrer votre appréciation peut illuminer
              leur journée et renforcer leur engagement envers vous et votre
              foyer.Nous vous encourageons à reconnaître leur travail et leur
              dévouement. Un simple sourire, un mot de remerciement, ou même une
              brève conversation pour montrer votre appréciation peut illuminer
              leur journée et renforcer leur engagement envers vous et votre
              foyer.
            </p>
            <p>
              En somme, nos intervenantes de ménage ne sont pas seulement des
              employées, mais des membres essentiels de votre foyer. Leur
              travail va bien au-delà du nettoyage ; elles créent un
              environnement propice à votre bien-être physique et mental. Nous
              vous invitons à reconnaître leur contribution inestimable et à
              valoriser leur présence dans votre vie quotidienne.
            </p>
            <p>
              Merci de votre attention et de votre soutien envers nos
              intervenantes de ménage.
            </p>
            <p>Cordialement,</p>
            <p>L'équipe Bulle</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Intervenants;
