import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgCopropriete from "../../assets/img_copropriete.png";
import "../../style/Services.css";

function Coproprietes() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta
          name="description"
          content="Bulle propose des services de nettoyage de copropriété dans le Var. Solutions professionnelles pour maintenir des espaces communs propres et accueillants."
        />
        <title>Bulle - Nettoyage de Copropriété dans le Var</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          {/* <Col xs={12} className='d-flex justify-content-center align-items-center'>
                        <h1 className="titre">Nettoyage de copropriétés</h1>
                    </Col> */}
          <Col xs={12} lg={6} className="texte-justify">
            <h1 className="titre mb-4">Nettoyage de copropriétés</h1>
            <p>
              Vous êtes propriétaires d’une copropriété et souhaitez offrir une
              belle expérience de vie dans votre résidence à vos habitants ?
              Nous sommes là pour vous aider à satisfaire leur bonheur et leur
              bien être afin qu’ils vivent dans une vraie bulle de propreté.
            </p>
            <p>
              Chez Bulle, nous comprenons que la propreté et la présentation
              soignée d'une copropriété sont essentielles pour créer un
              environnement accueillant et agréable pour les résidents. En tant
              que spécialistes du nettoyage de copropriétés, nous sommes
              déterminés à offrir des services fiables et de qualité pour
              répondre aux besoins uniques de chaque espace.
            </p>
            <p>
              Contactez-nous pour la réalisation de votre devis comprenant :
            </p>
            <ul className="liste">
              <li className="mb-2">
                <strong>Entretien des parties communes : </strong>
                Nous assurons le nettoyage régulier et méticuleux des halls
                d'entrée, des escaliers, des couloirs et des zones communes,
                créant ainsi un cadre propre et attrayant pour tous les
                résidents.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des espaces extérieurs : </strong>
                Nos équipes veillent à l'entretien des espaces extérieurs, y
                compris les cours, les aires de jeux et les zones de
                stationnement, pour garantir une apparence soignée et sécurisée.
              </li>
              <li className="mb-2">
                <strong>Gestion des déchets : </strong>
                Nous prenons en charge la collecte et l'élimination appropriée
                des déchets, contribuant ainsi à maintenir la propreté globale
                de la copropriété.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des vitres : </strong>
                Nous assurons le nettoyage des vitres intérieures et extérieures
                pour une visibilité maximale et une luminosité accrue.
              </li>
              <li className="mb-2">
                <strong>Services sur mesure : </strong>
                Nous sommes flexibles et pouvons adapter nos services en
                fonction des besoins spécifiques de votre copropriété, que ce
                soit pour des nettoyages ponctuels ou des programmes d'entretien
                réguliers.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center img-padding"
          >
            <Image
              src={ImgCopropriete}
              alt="Copropriétés"
              className="img-fluid mx-auto"
              roundedCircle
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-4">
            <h2 className="titre">
              Pourquoi Choisir Bulle pour le Nettoyage de Votre Copropriété ?
            </h2>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <ul className="liste-choix">
              <li>
                <strong>Professionnalisme : </strong>
                Nos équipes qualifiées et professionnelles sont formées pour
                travailler dans des environnements résidentiels, assurant un
                service respectueux et discret.
              </li>
              <li>
                <strong>Personnalisation des services : </strong>
                Nous comprenons que chaque copropriété est unique. C'est
                pourquoi nous travaillons en étroite collaboration avec vous
                pour élaborer des solutions de nettoyage personnalisées adaptées
                à vos besoins spécifiques.
              </li>
              <li>
                <strong>Engagement envers la satisfaction client : </strong>
                La satisfaction de nos clients est notre priorité. Nous nous
                efforçons de dépasser vos attentes à chaque intervention.
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={6} className="text-justify">
            <p>
              Un de nos intervenants vous sera attribué et nous ferons notre
              maximum pour que vous conserviez toujours ce même agent de service
              afin de vous offrir la meilleure expérience possible.
            </p>
            <p>
              Nous fournirons les produits et tout le matériel nécessaire en
              respectant les règles d’hygiènes et les obligations liés à votre
              copropriété.
            </p>
            <p>
              Confiez le nettoyage de votre copropriété à Bulle et bénéficiez
              d'un environnement propre, sécurisé et accueillant pour tous les
              résidents. Contactez-nous dès aujourd'hui pour discuter de vos
              besoins spécifiques et découvrir comment nous pouvons améliorer la
              propreté de votre espace de vie partagé.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Coproprietes;
