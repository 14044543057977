import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import '../../style/Form/ContactForm.css'

function ContactForm() {
    const [isFormSubmitted, setFormSubmitted] = useState(false);
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userId = process.env.REACT_APP_EMAILJS_USER_ID;


    const schema = yup.object({
        nom: yup
            .string()
            .max(50, "Le nom ne doit pas dépasser 50 caractères")
            .required("Veuillez entrer votre nom"),
        prenom: yup
            .string()
            .max(50, "Le prénom ne doit pas dépasser 50 caractères")
            .required("Veuillez entrer votre prénom"),
        email: yup
            .string()
            .email("Veuillez entrer une adresse email valide")
            .max(255, "L'adresse email ne doit pas dépasser 255 caractères")
            .required("Veuillez entrer votre adresse email"),
        phone: yup
            .string()
            .matches(/^[0-9]+$/, "Le numéro de téléphone ne doit contenir que des chiffres")
            .max(10, "Le numéro de téléphone doit avoir au maximum 10 chiffres")
            .typeError("Numéro invalide, veuillez entrer un numéro valide")
            .required("Veuillez entrer votre numéro de téléphone"),
        message: yup
            .string()
            // .max(255, "Le message ne doit pas dépasser 255 caractères")
            .required("Veuillez entrer votre message"),
        acceptePolitique: yup
            .boolean()
            .oneOf([true], "Vous devez accepter la politique de protection de la vie privée")
            .required("Vous devez accepter la politique de protection de la vie privée"),
    }).required()

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema)
    })

    const onSubmit = async (data) => {
        try {
            await schema.validate(data, { abortEarly: false });
            sendEmail();
            reset();
            setFormSubmitted(true);

            setTimeout(() => {
                setFormSubmitted(false);
            }, 10000);
        } catch (error) {
            // La validation a échoué, vous pouvez éventuellement gérer les erreurs ici
            console.error(error);
        }
    };


    const form = useRef();

    const sendEmail = () => {

        emailjs.sendForm(serviceId, templateId, form.current, userId)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <Container fluid="md" className="my-3">
            <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12}>
                        <h2 className="titre-h3 mb-3">
                            Contactez-nous
                        </h2>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="contactForm.Nom">
                            <Form.Label>Nom *</Form.Label>
                            <Form.Control
                                type="text"
                                name="nom"
                                placeholder="Nom"
                                {...register("nom")}
                            />
                            {errors.nom && (<p className='error-valide'>
                                <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
                                {errors.nom.message}</p>)}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="contactForm.Prenom">
                            <Form.Label>Prénom *</Form.Label>
                            <Form.Control
                                type="text"
                                name="prenom"
                                placeholder="Prénom"
                                {...register("prenom")}
                            />
                            {errors.prenom && (<p className='error-valide'>
                                <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
                                {errors.prenom.message}</p>)}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="contactForm.Email">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email"
                                {...register("email")}
                            />
                            {errors.email && (<p className='error-valide'>
                                <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
                                {errors.email.message}</p>)}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="contactForm.Phone">
                            <Form.Label>Téléphone *</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                placeholder="Téléphone"
                                {...register("phone")}
                            />
                            {errors.phone && (<p className='error-valide'>
                                <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
                                {errors.phone.message}</p>)}
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="contactForm.Message">
                            <Form.Label>Votre message *</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                placeholder='Votre message'
                                {...register("message")}
                                style={{ height: '200px' }}
                            />
                            {errors.message && (<p className='error-valide'>
                                <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
                                {errors.message.message}</p>)}
                        </Form.Group>
                    </Col>
                    <Col sx={12}>
                        <Form.Group className="mb-3" controlId="contactForm.AcceptePolitique">
                            <div className="custom-checkbox-label">
                                <Form.Check
                                    type="checkbox"
                                    id="acceptePolitique"
                                    name="acceptePolitique"
                                    className="custom-checkbox"
                                    {...register("acceptePolitique")}
                                />
                                <label htmlFor="acceptePolitique">
                                    J’ai pris connaissance de la manière dont mes données sont traitées et j’accepte les{' '}
                                    <Link to="/conditions-generales-utilisation">conditions générales d'utilisation du site</Link>
                                    {' '}*
                                </label>
                            </div>
                            {errors.acceptePolitique && (<p className='error-valide'>
                                <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
                                {errors.acceptePolitique.message}</p>)}
                        </Form.Group>
                    </Col>
                    <Col xs={12} className='mb-3'>
                        <Button
                            className='btn-good'
                            type="submit"
                        >
                            Envoyer
                        </Button>
                        {isFormSubmitted && <p>Le formulaire a été envoyé avec succès !</p>}
                    </Col>
                    <Col xs={12}>
                        <p>* champs obligatoires</p>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default ContactForm;