import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgIndustriel from "../../assets/img_industriel.jpg";
import "../../style/Services.css";

function Industriels() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Bulle propose des services de nettoyage industriel dans le Var. Solutions professionnelles pour répondre aux besoins de propreté et d'hygiène des installations industrielles."
        />
        <title>Bulle - Nettoyage Industriel dans le Var</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          {/* <Col xs={12} className='d-flex justify-content-center align-items-center'>
                        <h1 className="titre">Nettoyage industriel</h1>
                    </Col> */}
          <Col xs={12} lg={6} className="texte-justify">
            <h1 className="titre mb-4">Nettoyage industriel</h1>
            <p>
              Que vous apparteniez au secteur agroalimentaire, médical,
              industriel, ou à toute autre structure dotée d'ateliers et de
              machines, nous sommes là pour vous accompagner dans votre projet
              de nettoyage.
            </p>
            <p>
              Chez Bulle, nous comprenons les exigences particulières et les
              normes élevées associées au nettoyage industriel. Même si nous
              sommes nouveaux sur le marché du nettoyage industriel, nous sommes
              fiers de vous offrir des services de nettoyage fiables et
              efficaces, adaptés à une variété d'installations industrielles.
            </p>
            <ul className="liste">
              <li className="mb-2">
                <strong>Nettoyage des équipements : </strong>
                Nous nous spécialisons dans le nettoyage en profondeur des
                machines, équipements et installations industrielles pour
                garantir leur bon fonctionnement et prolonger leur durée de vie.
              </li>
              <li className="mb-2">
                <strong>Entretien des sols industriels : </strong>
                Que vous ayez des sols en béton, en époxy, en carrelage ou
                autres, nos équipes utilisent des techniques avancées pour
                enlever les résidus industriels et maintenir des surfaces
                propres et sécurisées.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des zones de production : </strong>
                Nous assurons un nettoyage approfondi des zones de production,
                en éliminant les débris, les poussières et autres contaminants,
                conformément aux normes industrielles les plus strictes.
              </li>
              <li className="mb-2">
                <strong>Gestion des déchets industriels : </strong>
                Nous gérons de manière responsable l'élimination des déchets
                industriels, en respectant les réglementations en vigueur et en
                contribuant à votre engagement envers la durabilité.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des espaces de stockage : </strong>
                Nos équipes veillent à ce que vos entrepôts et espaces de
                stockage soient maintenus dans un état optimal, favorisant
                l'efficacité opérationnelle.
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center img-padding"
          >
            <Image
              src={ImgIndustriel}
              alt="Industiel"
              className="img-fluid mx-auto"
              roundedCircle
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-4">
            <h2 className="titre">
              Pourquoi Choisir Bulle pour le Nettoyage Industriel ?
            </h2>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <ul className="liste-choix">
              <li>
                <strong>Conformité aux normes industrielles : </strong>
                Nous comprenons les réglementations strictes entourant les
                environnements industriels et nous nous engageons à respecter
                toutes les normes de sécurité et de propreté.
              </li>
              <li>
                <strong>Flexibilité opérationnelle : </strong>
                Nous adaptons nos services en fonction de vos horaires de
                production pour minimiser les interruptions tout en garantissant
                un environnement de travail propre.
              </li>
              <li>
                <strong>Équipes qualifiées : </strong>
                Nos équipes sont formées pour travailler dans des environnements
                industriels complexes, assurant un service professionnel et
                efficace.
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={6} className="text-justify">
            <p>
              Nous nous engageons à fournir tous les produits et matériels
              nécessaires conformément à toutes les règles et obligations
              d’hygiène dans le milieu industriel, et à vous confier la
              meilleure de nos équipes.
            </p>
            <p>
              Confiez vos besoins de nettoyage industriel à Bulle et découvrez
              la différence que notre expertise peut apporter à votre site
              industriel. Contactez-nous dès aujourd'hui pour discuter de vos
              besoins spécifiques et découvrir comment nous pouvons optimiser la
              propreté et l'efficacité de votre installation industrielle.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Industriels;
