import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MyNavbar from './Navbar';
import Home from './Home';
import Bureau from './ServicesPro/Bureau';
import FinChantier from './ServicesPro/FinChantier';
import Industriels from './ServicesPro/Industriels';
import Coproprietes from './ServicesPro/Coproprietes';
import Voiture from './ServicesPart/Voiture';
import Particuliers from './ServicesPart/Particuliers';
import Intervenants from './Intervenants';
import MentionsLegales from './RGPD/MentionsLegales';
import CGU from './RGPD/CGU';
import Footer from './Footer';
import '../style/App.css';

function App() {
  return (
    <div className='site-container bg-body-tertiary'>
      <MyNavbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/nettoyage-bureau' element={<Bureau />} />
        <Route path='/nettoyage-fin-chantier' element={<FinChantier />} />
        <Route path='/nettoyage-industriel' element={<Industriels />} />
        <Route path='/nettoyage-coproprietes' element={<Coproprietes />} />
        <Route path='/nettoyage-voiture' element={<Voiture />} />
        <Route path='/particuliers' element={<Particuliers />} />
        <Route path='/intervenants' element={<Intervenants />} />
        <Route path='/mentions-legals' element={<MentionsLegales />} />
        <Route path='/conditions-generales-utilisation' element={<CGU />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
