import React, { useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import Logo from "../assets/home/img_logo_Bulle.png";
import ImgProfessionnels from '../assets/home/img_bulle_professionnels.png';
import ImgParticuliers from '../assets/home/img_bulle_particuliers.png';
import ImgIntervenants from '../assets/home/img_bulle_intervenants.png';
import ImgContact from '../assets/home/img_bulle_contact.png';
import '../style/Navbar.css';

function MyNavbar() {
    const expand = 'xl';
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const location = useLocation();

    const handleOffcanvasClose = () => setShowOffcanvas(false);

    // Ajouter cette fonction scrollToTop
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto' // Optional: Ajouter un comportement fluide
        });
    };

    React.useEffect(() => {
        scrollToTop();
    }, [location]);

    return (
        <Navbar sticky="top" expand={expand} className="bg-navbar">
            <Container fluid>
                <Navbar.Brand as={NavLink} to="/" >
                    <img
                        src={Logo}
                        alt="Logo de Bulle"
                        width={175}
                        roundedCircle
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowOffcanvas(!showOffcanvas)} />
                <Navbar.Offcanvas
                    show={showOffcanvas}
                    onHide={handleOffcanvasClose}
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="start"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <img
                                src={Logo}
                                alt="Logo de Bulle"
                                width={175}
                                roundedCircle
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <NavDropdown
                                id={`offcanvasNavbarDropdown-pro-${expand}`}
                                className="custom-dropdown"
                                title={
                                    <img
                                        src={ImgProfessionnels}
                                        alt="Services de nettoyage professionnel"
                                        width={175}
                                    />
                                }
                            >
                                <NavDropdown.Item as={NavLink} to="/nettoyage-bureau" onClick={handleOffcanvasClose}>Nettoyage de bureau</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/nettoyage-fin-chantier" onClick={handleOffcanvasClose}>Nettoyage de fin de chantier</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/nettoyage-industriel" onClick={handleOffcanvasClose}>Nettoyage industriel</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/nettoyage-coproprietes" onClick={handleOffcanvasClose}>Nettoyage de copropriétés</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                id={`offcanvasNavbarDropdown-part-${expand}`}
                                className="custom-dropdown"
                                title={
                                    <img
                                        src={ImgParticuliers}
                                        alt="Services de nettoyage particuliers"
                                        width={175}
                                    />
                                }
                            >
                                <NavDropdown.Item as={NavLink} to="/nettoyage-voiture" onClick={handleOffcanvasClose}>Nettoyage de voiture</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/particuliers" onClick={handleOffcanvasClose}>Particuliers</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={NavLink} to="/intervenants" onClick={handleOffcanvasClose}>
                                <img
                                    src={ImgIntervenants}
                                    alt="Intervenants"
                                    width={175}
                                />
                            </Nav.Link>
                            <Nav.Link as={NavHashLink} to="/#contact" className="nav-link-with-text" onClick={handleOffcanvasClose}>
                                <div className="nav-link-container">
                                    <img
                                        src={ImgContact}
                                        alt="Contactez-nous"
                                        width={175}
                                    />
                                </div>
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default MyNavbar;
