import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgBureau from "../../assets/img_bureau.jpg";
import "../../style/Services.css";

function Bureau() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Bulle propose des services de nettoyage de bureau dans le Var. Des solutions professionnelles pour maintenir des espaces de travail propres et agréables."
        />
        <title>Bulle - Nettoyage de Bureau dans le Var</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          {/* <Col xs={12} className='d-flex justify-content-start align-items-center mb-3'>
                        <h1 className="titre">Nettoyage de bureau</h1>
                    </Col> */}
          <Col xs={12} lg={6} className="texte-justify">
            <h1 className="titre mb-4">Nettoyage de bureau</h1>
            <p>
              Vous souhaitez faire nettoyer vos bureaux afin que vos employés
              aient une atmosphère de travail propre et saine et que vos clients
              soient accueillis dans les meilleures conditions ?
            </p>
            <p>
              Alors laissez-nous rentrer dans votre bulle afin que nous
              puissions répondre à tous vos besoins. Nous nous engageons à vous
              fournir les meilleurs services grâce à nos intervenantes qui vous
              fourniront un travail irréprochable. Nous assurerons le nettoyage
              intégral de vos locaux comme :
            </p>
            <ul className="liste">
              <li className="mb-2">
                <strong>Entretien quotidien : </strong>Nos équipes dévouées
                assurent un nettoyage régulier et approfondi de vos espaces de
                travail, y compris le dépoussiérage, l'aspiration des tapis, le
                nettoyage des surfaces, et la désinfection des points de contact
                fréquents.
              </li>
              <li className="mb-2">
                <strong>Gestion des déchets : </strong>Nous nous occupons de la
                collecte et de l'élimination appropriée des déchets, contribuant
                ainsi à maintenir un environnement propre et respectueux de
                l'environnement.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des espaces communs : </strong>Les zones
                communes telles que les cuisines, les salles de repos et les
                salles de réunions sont traitées avec la même attention
                méticuleuse que les bureaux individuels.
              </li>
              <li className="mb-2">
                <strong>Nettoyage des sols : </strong>Que vos sols soient en
                moquette, en carrelage ou en bois, nous utilisons les méthodes
                et les produits les plus adaptés pour assurer un nettoyage
                optimal et une apparence impeccable.
              </li>
              <li className="mb-2">
                <strong>Services spécialisés : </strong>Nous proposons également
                des services spécialisés tels que le nettoyage des vitres, le
                shampooing de moquette, nettoyage approfondi des fauteuils…
              </li>
            </ul>
            <p>
              Nous souhaitons améliorer le quotidien de vos salariés et non le
              déranger, nous prenons donc soin de tout remettre à sa place.
            </p>
            <p>
              Confiez le nettoyage de vos bureaux à Bulle et bénéficiez d'un
              environnement de travail impeccable, propice à la productivité et
              à la satisfaction de vos employés. Contactez-nous dès aujourd'hui
              pour une consultation personnalisée et découvrez comment nous
              pouvons répondre à vos besoins spécifiques en matière de
              nettoyage.
            </p>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center img-padding"
          >
            <Image
              src={ImgBureau}
              alt="Bureau"
              className="img-fluid mx-auto"
              roundedCircle
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-4">
            <h2 className="titre">
              Pourquoi Choisir BULLE pour le Nettoyage de vos Bureaux ?
            </h2>
          </Col>
          <Col xs={12} lg={6} className="text-justify">
            <ul className="liste-choix">
              <li>
                <strong>Professionnalisme : </strong>Nos équipes sont formées,
                qualifiées et professionnelles, garantissant un service de haute
                qualité à chaque intervention.
              </li>
              <li>
                <strong>Flexibilité : </strong>Nous comprenons que chaque
                entreprise a des besoins uniques. C'est pourquoi nous adaptons
                nos services pour répondre à vos exigences spécifiques en terme
                de fréquences, d'horaires et de services.
              </li>
              <li>
                <strong>Produits respectueux de l'environnement : </strong>
                Nous utilisons des produits de nettoyage écologiques qui sont à
                la fois efficaces et respectueux de l'environnement, contribuant
                ainsi à la durabilité de votre entreprise.
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={6} className="text-justify">
            <p>
              Un de nos intervenants vous sera attribué et nous ferons notre
              maximum pour que vous conserviez toujours ce même agent de service
              afin de vous offrir la meilleure expérience possible.
            </p>
            <p>
              Conscients et soucieux de la quantité de travail à laquelle vous
              devez faire face au quotidien, nous tenterons de l’alléger en vous
              proposant les meilleures prestations possibles.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Bureau;
