import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ImgLocal from '../assets/home/img_fond_qsn.jpeg'
import "../style/QuiSommesNous.css";


function QuiSommesNous() {
    return (
        <Container fluid="md" className="my-3">
            <Row >
                <Col xs={12}>
                    <h2 className="accroche mb-3">
                        La propreté, l'hygiène et l'environnement professionels à votre service
                    </h2>
                </Col>
                <Col xs={12}>
                    <h3>
                        Qui sommes nous ?
                    </h3>
                </Col>
                <Col xs={12} lg={6} className='qsn-texte'>
                    <p>
                        Chez BULLE, nous inaugurons un nouveau chapitre dans l'univers du ménage professionnel. Fondée récemment par Camille et Thierry Gimenez, notre entreprise
                        incarne une vision commune de qualité, d'engagement et d'innovation dans le domaine du nettoyage.
                    </p>
                    <p>
                        En tant qu'entreprise familiale, nous croyons en la force de la collaboration. La synergie entre Thierry Gimenez,
                        fort d'une expérience en tant que PDG de différentes entreprises dans le domaine du bâtiment, et Camille Gimenez, nouvellement
                        diplômée d’un master et après 2 ans d’expériences dans une société de ménage apportant une perspective moderne et dynamique, est la clé de
                        notre approche unique du nettoyage professionnel.

                    </p>
                    <p>
                        Bien que notre histoire soit récente, notre engagement envers l'excellence est déjà profondément enraciné. Nous sommes déterminés à bâtir
                        une entreprise qui repose sur la confiance, la transparence et la satisfaction de nos clients. Nous tenons également à respecter notre planète
                        en utilisant des produits et des méthodes écologiques.
                    </p>
                    <p>
                        Chez BULLE, nous aspirons à redéfinir les normes de propreté industrielle. Nous ne considérons pas simplement le nettoyage comme une tâche, mais
                        comme une contribution essentielle à l'environnement de travail de nos clients. Nous nous efforçons de créer des espaces impeccables qui favorisent
                        le bien-être et la productivité.
                    </p>
                    <p>
                        Nous plaçons nos clients au cœur de tout ce que nous faisons. Chaque projet est une opportunité de surpasser les attentes, de fournir des solutions
                        sur mesure et de construire des relations durables.
                    </p>
                    <p>
                        Joignez-vous à nous dans cette aventure passionnante vers des espaces propres et inspirants.
                    </p>
                    <p>
                        Contactez-nous dès aujourd'hui pour discuter de vos besoins en matière de nettoyage. Merci de choisir BULLE pour vos solutions de propreté.
                    </p>
                    <p>
                        Bien proprement,
                    </p>
                    <p>
                        Camille et Thierry Gimenez
                    </p>
                </Col>
                <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center">
                    <Image
                        src={ImgLocal}
                        alt="Open Space"
                        className="img-fluid"
                        roundedCircle
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default QuiSommesNous;