import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import ImgParticulier from "../../assets/img_particulier.png";
import "../../style/Services.css";

function Particuliers() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Bulle propose des services de nettoyage pour particuliers dans le Var. Solutions professionnelles pour maintenir la propreté et le confort de votre domicile."
        />
        <title>Bulle - Nettoyage pour Particuliers dans le Var</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          {/* <Col xs={12} className='d-flex justify-content-center align-items-center'>
                        <h1 className="titre">Nettoyage pour particuliers</h1>
                    </Col> */}
          <Col xs={12} lg={6} className="texte-justify">
            <h1 className="titre mb-4">Nettoyage pour particuliers</h1>
            <p>
              Chez Bulle, nous comprenons l'importance d'un espace propre et
              bien entretenu dans la vie quotidienne. Nos services de nettoyage
              dédiés aux particuliers sont conçus pour vous offrir une
              tranquillité d'esprit, en vous libérant du fardeau des tâches
              ménagères. Faites de votre foyer un endroit où règne la propreté
              et la fraîcheur, grâce à notre équipe dévouée de professionnels du
              nettoyage.
            </p>
            <p>
              Tous nos devis sont faits sur mesure, nous nous déplaçons à votre
              domicile et faisons le point ensemble sur tous vos besoins. Votre
              satisfaction est notre priorité.
            </p>
            <p>
              <strong>Nettoyage Résidentiel Personnalisé : </strong>
              Nous adaptons nos services à vos besoins spécifiques. Que ce soit
              pour un nettoyage régulier, une grande occasion, ou une assistance
              ponctuelle, notre équipe s'engage à répondre à vos attentes :
            </p>
            <ul className="liste">
              <li className="mb-2">
                <strong>Dépoussiérage : </strong>Nous dépoussiérons tous vos
                meubles chaque semaine à l’aide de microfibres.
              </li>
              <li className="mb-2">
                <strong>Lavage des sols : </strong>Nous aspirons chaque recoin
                de votre maison et lavons les sols sans laisser de traces.
              </li>
              <li className="mb-2">
                <strong>Lavage des sanitaires : </strong>Nous nettoyons salle de
                bain et toilettes avec des techniques de ménage élaborées.
              </li>
              <li className="mb-2">
                <strong>Nettoyage approfondi de la cuisine : </strong>Nous
                nettoyons votre cuisine de fond en comble, nous pouvons à votre
                demande nettoyer l’intérieur des placards, l’intérieur de votre
                frigo, de votre four…
              </li>
              <li className="mb-2">
                <strong>Vitres et encadrements : </strong>Nous réalisons le
                nettoyage de vos vitres en roulement afin de conserver des
                vitres propres semaines après semaines.
              </li>
            </ul>
            <p>
              Nous proposons également un service de nettoyage ponctuel où nous
              pourrons réaliser toutes vos demandes, que ce soit pour une remise
              en état de la maison, un emménagement ou déménagement...
            </p>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-center align-items-center img-padding"
          >
            <Image
              src={ImgParticulier}
              alt="Particulier"
              className="img-fluid mx-auto"
              roundedCircle
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mb-4">
            <h2 className="titre">
              Pourquoi choisir Bulle pour votre nettoyage ?
            </h2>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <ul className="liste-choix">
              <li>
                <strong>Professionnalisme : </strong>
                Nos équipes sont composées de professionnels formés et
                expérimentés, garantissant un service de haute qualité à chaque
                visite.
              </li>
              <li>
                <strong>Personnalisation : </strong>
                Nous comprenons que chaque domicile est unique. C'est pourquoi
                nous personnalisons nos services pour répondre à vos exigences
                spécifiques.
              </li>
              <li>
                <strong>Produits Écologiques : </strong>
                Engagés envers l'environnement, nous utilisons des produits de
                nettoyage écologiques qui sont à la fois efficaces et
                respectueux de votre santé.
              </li>
              <li>
                <strong>Confidentialité : </strong>
                Votre vie privée est notre priorité. Nous traitons chaque
                domicile avec le plus grand respect et la plus grande
                discrétion.
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={6} className="texte-justify">
            <p>
              Faites le choix de la propreté sans compromis avec Bulle.
              Contactez-nous dès aujourd'hui pour bénéficier de nos services de
              nettoyage exceptionnels et redécouvrez la joie d'un chez-soi
              impeccable.
            </p>
            <p>
              Attention, nous ne bénéficions pas de l'avance immédiate du crédit
              d'impôts pour les particuliers.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Particuliers;
