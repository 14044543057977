import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import "../../style/Services.css";

function CGU() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Consultez les Conditions Générales d'Utilisation de Bulle, société de services de ménage et de nettoyage dans le Var. Informations légales sur l'utilisation de notre site."
        />
        <title>Bulle - Conditions Générales d'Utilisation</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <h1 className="titre">Conditions Générales d’Utilisation</h1>
          </Col>
          <Col xs={12} className="text-justify">
            <section id="presentation">
              <p>
                Les présentes conditions générales d'utilisation (dites « CGU »)
                ont pour objet l'encadrement juridique des modalités de mise à
                disposition du site et des services par le gérant du site
                internet, ci-après désigné « l'Éditeur », et de définir les
                conditions d’accès et d’utilisation des services par toute
                personne souhaitant accéder au site et à ses services, ci-après
                appelé « l'Utilisateur ».
              </p>
              <p>
                Tout accès et utilisation de ce site indiquent que l’utilisateur
                a pris connaissance de ces conditions d’utilisation et a accepté
                d’y être lié. En cas contraire, l’internaute ne doit pas
                utiliser ou accéder à ce site et le quitter immédiatement.
              </p>
              <p>
                Les présentes CGU sont accessibles sur le site à la rubrique
                «CGU».
              </p>
            </section>
            <section id="acces_utilisation_site">
              <h3>Accès & utilisation du site</h3>
              <p>
                Tout Utilisateur ayant accès à internet peut accéder
                gratuitement et depuis n'importe ou au site Bulle. Les frais
                supportés par l'Utilisateur pour y accéder (connexion internet,
                matériel informatique, etc.) ne sont pas à la charge de
                l'Éditeur.
              </p>
              <p>
                Le site et ses différents services peuvent être interrompus ou
                suspendus par l'Éditeur, notamment à l'occasion d'une
                maintenance, sans obligation de préavis ou de justification.
              </p>
            </section>

            <section id="Propriete_intellectuelle">
              <h3>Propriété intellectuelle</h3>
              <p>
                Crédit des photographiques : Les images utilisé sur ce site sont
                tirées de banque d’image libre de droit sur les sites{" "}
                <a href="https://unsplash.com/fr" target="blank">
                  Unsplash
                </a>{" "}
                et{" "}
                <a href="https://fr.freepik.com" target="blank">
                  Freepik
                </a>
              </p>
            </section>

            <section id="donnees_personnelles">
              <h3>Données personnelles</h3>
              <p>
                Les données personnelles collecter sur le formulaire de contact
                sont de l’ordre du nom, du prénom, de l’adresse électronique et
                du numéro de téléphone.
              </p>
              <p>Elles seront transmises à :</p>
              <ul>
                <li>Camille Gimenez</li>
                <li>Thierry Gimenez</li>
              </ul>
              <p>
                Via le site{" "}
                <a href="https://www.emailjs.com" target="blank">
                  EmailJs
                </a>{" "}
                et seront conservé sur le site durant 30 jours.
              </p>
              <p>
                Vous pourrez trouver{" "}
                <a
                  href="https://www.emailjs.com/legal/privacy-policy/"
                  target="blank"
                >
                  ici
                </a>{" "}
                la politique de confidentialité du site EmailJs.
              </p>
              <p>Les données seront consultables par :</p>
              <ul>
                <li>Camille Gimenez</li>
                <li>Thierry Gimenez</li>
                <li>
                  Elles pourront également être communiquées à toute personne
                  habilitée
                </li>
              </ul>
              <p>
                Vos données seront gardées pendant un an, à partir du jour où
                elles ont été collectées, si aucune suite n'est donnée au devis.
                Si vous décidez de nous contacter pendant cette période d'un an,
                nous aurons déjà vos informations.
              </p>
              <p>
                Nos clients bénéficient de la conservation de leurs données
                pendant toute la durée du contrat, puis pendant un an après la
                fin du contrat, conformément aux raisons mentionnées
                précédemment.
              </p>
              <p>
                En vertu de la loi Informatique et Libertés, en date du 6
                janvier 1978, l'Utilisateur dispose d'un droit d'accès, de
                rectification, de suppression et d'opposition de ses données
                personnelles.
              </p>
              <p>L'Utilisateur exerce ce droit :</p>
              <ul>
                <li>
                  par mail à l'adresse email :{" "}
                  <strong>cgimenez.bulle@outlook.fr</strong>
                </li>
                <li>
                  par voie postale au :{" "}
                  <strong>
                    268 Route du Plan de la Tour, Sainte-Maxime (83115)
                  </strong>
                </li>
              </ul>
            </section>

            <section id="liens_hypertextes">
              <h3>Les liens hypertextes</h3>
              <p>
                Des liens hypertextes peuvent être présents sur le site.
                L’Utilisateur est informé qu’en cliquant sur ces liens, il
                sortira du site https://www.bulle.fr. Ce dernier n’a pas de
                contrôle sur les pages web sur lesquelles aboutissent ces liens
                et ne saurait, en aucun cas, être responsable de leur contenu.
              </p>
              <p>
                Il est interdit de mettre en place un lien hypertexte vers le
                présent site sans l’accord écrit et préalable de l’éditeur.
              </p>
            </section>

            <section id="droit_applicable">
              <h3>Droit applicable</h3>
              <p>
                Les présentes conditions générales d'utilisation sont soumises à
                l'application du droit français.
              </p>
              <p>
                Si les parties n'arrivent pas à résoudre un litige à l'amiable,
                le litige sera soumis à la compétence des tribunaux français.
              </p>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CGU;
