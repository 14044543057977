import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import '../style/Footer.css'

function MyFooter() {
    const instagramUsername = 'bulle.pro'; // Pour modifié l'instagram
    const tiktokUsername = 'bulle.pro';

    return (
        <footer className="bg-footer d-flex flex-column py-3">
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <h5>Bulle</h5>
                        <ul className="list-unstyled">
                            <li className="mb-1">
                                <h6>&copy; 2024. Tous droits réservés.</h6>
                            </li>
                            <li className="mb-1">
                                <Link to="/mentions-legals" className="social-link">Mentions Légales</Link>
                            </li>
                            <li className="mb-1">
                                <Link to="/conditions-generales-utilisation" className="social-link">CGU</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <h5>Contactez-nous</h5>
                        <ul className="list-unstyled m-0">
                            <li className="mb-1">
                                <a
                                    href={`https://www.instagram.com/${instagramUsername}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="social-link"
                                >
                                    <FontAwesomeIcon icon={faInstagram} size="xl" className="icon-style" />
                                    <span> @{instagramUsername}</span>
                                </a>
                            </li>
                            <li className="mb-1">
                                <a
                                    href={`https://www.tiktok.com/@${tiktokUsername}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="social-link"
                                >
                                    <FontAwesomeIcon icon={faTiktok} size="xl" className="icon-style" />
                                    <span> @{tiktokUsername}</span>
                                </a>
                            </li>
                            <li className="mb-1">
                                <FontAwesomeIcon icon={faPhone} size="xl" className="icon-style" />
                                <span> 07.69.51.19.11</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faEnvelopeOpenText} size="xl" className="icon-style" />
                                <span> cgimenez.bulle@outlook.fr</span>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default MyFooter;