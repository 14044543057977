import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import "../../style/Services.css";

function MentionsLegales() {
  return (
    <div>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P7P73N45');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7JZ2GMGNFZ"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7JZ2GMGNFZ');`}
        </script>
        {/* End Google tag */}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Consultez les mentions légales de Bulle, société de services de ménage et de nettoyage dans le Var. Informations juridiques et légales sur notre entreprise."
        />
        <title>Bulle - Mentions Légales</title>
      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-P7P73N45"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <Container fluid="md">
        <Row className="mb-3">
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <h1 className="titre">Mentions légales</h1>
          </Col>
          <Col xs={12}>
            <ul className="list-unstyled">
              <li>
                <strong>Dénomination Sociale : </strong>BULLE
              </li>
              <li>
                <strong>Forme Juridique : </strong>SAS
              </li>
              <li>
                <strong>Capital social : </strong>1000 euros
              </li>
              <li>
                <strong>N° SIRET : </strong>982 766 370 00018
              </li>
              <li>
                <strong>Adresse : </strong>268 route du plan de la tour
              </li>
              <li>
                <strong>Code postal :</strong> 83120
              </li>
              <li>
                <strong>Ville : </strong>Sainte Maxime
              </li>
              <li>
                <strong>Téléphone : </strong>07.69.51.19.11
              </li>
              <li>
                <strong>Adresse électronique : </strong>
                cgimenez.bulle@outlook.fr
              </li>
              <li>
                <strong>N° d'assujettissement à la TVA : </strong>
                FR30982766370
              </li>
              <li>
                <strong>
                  Numéro d’inscription au registre du commerce et des sociétés
                  (RCS) :{" "}
                </strong>
                982 766 370
              </li>
              <li>
                <strong>
                  Numéro registre national unique des entreprises (RNE) :{" "}
                </strong>{" "}
                Inscrit
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MentionsLegales;
